import { TextScript, TextString } from '@MGPD/myasurion-shared/components/localization';
import { AccountInfo, useAccount } from '@MGPD/myasurion-shared/hooks';
import { Mixpanel } from '@MGPD/myasurion-shared/utils/mixpanel';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Link,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Skeleton,
    SkeletonText,
    Spinner,
    Tag,
    Text,
    TextProps,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { ArticleCardProps } from '../types/types';
import ScreenReaderText from './ScreenReaderText';

export const TextBold: React.FC<TextProps> = ({ children, ...props }) => {
    return (
        <Text fontWeight="bold" {...props}>
            {children}
        </Text>
    );
};

export const ServiceTooltip = ({ title, body }: { title: string; body: ReactNode | string }) => {
    return (
        <Tooltip as={Popover} isOpen={true} aria-label={`Tooltip for ${title}`} title={title}>
            <Popover placement="top" aria-label={`Popover for ${title}`}>
                <PopoverTrigger>
                    <Button
                        variant="unstyled"
                        h={{ lg: 'max-content' }}
                        zIndex={10}
                        aria-label={`Popover button for ${title}`}
                        title={title}
                    >
                        <QuestionOutlineIcon boxSize="20px" />
                    </Button>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        bg="#F0F0F5"
                        maxW="310px"
                        display="flex"
                        flexFlow="column nowrap"
                        gap="4px"
                        zIndex={999}
                    >
                        <PopoverHeader border="none" fontWeight="700" pb="0">
                            <TextScript text={title} translationKey={`Tooltip/Title_${title}`} />
                        </PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody pt="0" fontSize="14px">
                            <Text fontSize="14px">
                                {typeof body === 'string' ? (
                                    <TextScript
                                        text={body}
                                        translationKey={`Tooltip/Body_${body?.substring(0, 50)}`}
                                    />
                                ) : (
                                    body
                                )}
                            </Text>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </Tooltip>
    );
};

export const ServiceSpinner = () => {
    return (
        <Box w={'full'} textAlign={'center'}>
            <Spinner />
        </Box>
    );
};

export const ServiceTitle = ({
    title,
    translationKey,
}: {
    title: string;
    translationKey: `${string}/${string}`;
}) => {
    return (
        <Text fontSize="24px">
            <TextString text={title} translationKey={translationKey} />
        </Text>
    );
};

export const ArticleCard: React.FC<ArticleCardProps> = ({
    image,
    size,
    tag,
    tagProps,
    category,
    title,
    titleProps,
    description,
    linkText,
    link,
    linkType,
    linkProps,
    isLoading,
    isComplete,
    props,
}) => {
    const [account] = useAccount<AccountInfo>({
        userId: '',
        profileId: '',
    });
    return (
        <VStack {...props} spacing={1} h={'full'} w={'full'}>
            <Box w="full" h={size && size === 'lg' ? '55%' : '100%'} id="image" flex={0}>
                <Skeleton isLoaded={!isLoading && isComplete}>
                    <Box
                        h="full"
                        bgImage={`url(${image})`}
                        bgRepeat="no-repeat"
                        p={3}
                        bgPosition="top center"
                        bgSize={{ base: 'cover' }}
                        borderRadius="8px"
                        height="200px"
                    >
                        {tag && (
                            <Tag {...tagProps} textTransform="capitalize">
                                {tag}
                            </Tag>
                        )}
                    </Box>
                </Skeleton>
            </Box>
            <VStack
                h={size && size === 'lg' ? '45%' : '100%'}
                alignItems="start"
                justifyContent="space-between"
                p={3}
                gap={2}
                spacing={0}
                flex={1}
                id="article-content"
            >
                <Box>
                    {category && (
                        <Skeleton
                            isLoaded={!isLoading && isComplete}
                            w={isLoading && !isComplete ? '40%' : 'auto'}
                            mb={isLoading && !isComplete ? '3px' : 0}
                        >
                            <TextBold fontSize="14px">{category}</TextBold>
                        </Skeleton>
                    )}
                    {titleProps ? (
                        <Skeleton
                            isLoaded={!isLoading && isComplete}
                            mb={isLoading && !isComplete ? '3px' : 0}
                        >
                            <Text {...titleProps} line-height="124%" mb={1}>
                                {title}
                            </Text>
                        </Skeleton>
                    ) : (
                        <Skeleton isLoaded={!isLoading && isComplete}>
                            <Text fontSize="20px" line-height="124%" mb={1}>
                                {title}
                            </Text>
                        </Skeleton>
                    )}
                    <SkeletonText isLoaded={!isLoading && isComplete}>
                        <Text line-height="124%" noOfLines={5}>
                            {description}
                        </Text>
                    </SkeletonText>
                </Box>
                {linkType === 'button' && linkProps ? (
                    <Skeleton isLoaded={!isLoading && isComplete}>
                        {/* <Button {...(linkProps ?? '')}>{linkText}</Button> */}
                    </Skeleton>
                ) : (
                    <Skeleton isLoaded={!isLoading && isComplete}>
                        <Link
                            href={`/articles/expert-tech-tips/${category}${link}`}
                            textDecor="underline"
                            target="_blank"
                            rel="opener"
                            fontWeight="bold"
                            onClick={() =>
                                Mixpanel.sendMixpanelEvent(
                                    'ASMA_Portal_ArticleCard_ClickLearnMore',
                                    {
                                        userId: account.userId,
                                    }
                                )
                            }
                        >
                            {linkText}
                            <ScreenReaderText />
                        </Link>
                    </Skeleton>
                )}
            </VStack>
        </VStack>
    );
};
